import firebase from "firebase/app";
import "firebase/messaging";
import store from "@/core/services/store";
import notification from "@/assets/audio/notification.mp3";

const config = {
  apiKey: "AIzaSyA0GDtcXEvWTT8XUTuVVii6VnmqKtn9MCU",
  authDomain: "fx-oms.firebaseapp.com",
  projectId: "fx-oms",
  storageBucket: "fx-oms.appspot.com",
  messagingSenderId: "999260490882",
  appId: "1:999260490882:web:4597b7485730cb7e50789b",
  measurementId: "G-3QRG4NQ3NC"
};

firebase.initializeApp(config);
const messaging = firebase.messaging();

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    if (Notification.permission === "default") {
      Notification.requestPermission().then(permission => {
        if (permission === "granted") {
          messaging
            .getToken()
            .then(firebaseToken => {
              resolve(firebaseToken);
            })
            .catch(err => {
              console.log(err);
              reject(err);
            });
        } else {
          resolve("");
        }
      });
    } else if (Notification.permission === "granted") {
      messaging
        .getToken()
        .then(firebaseToken => {
          resolve(firebaseToken);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    } else {
      resolve("");
    }
  });

let note = new Audio(notification);

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.onMessage(payload => {
      note.play();
      store.dispatch("onMessage", payload.notification);
      resolve(payload);
    });
  });
